<template>
  <button
    v-on="$listeners"
    v-bind="$attrs"
    class="custom-button btn-primary"
    :id="buttonProps.id"
    :data-testid="buttonProps.id"
    :name="buttonProps.id"
    :class="{ loading: buttonProps.isLoading }"
    :disabled="buttonProps.isLoading"
  >
    <div class="button-progress" />
    <span class="button-text">{{ buttonProps.buttonText }}</span>
    <span class="material-icons" v-if="buttonProps.forwardArrowEnabled">arrow_forward</span>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    buttonProps: {
      type: Object,
      required: true
    }
  },
  methods: {
    // Sets the current width of the progress bar based on the loading percentage.
    setButtonProgress(percent) {
      const currentButton = document.getElementById(this.buttonProps.id)

      currentButton.querySelector('.button-progress').style.width = `${percent}%`
    }
  },
  watch: {
    'buttonProps.loadingPercentage': function (newValue) {
      this.setButtonProgress(newValue)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.custom-button {
  height: 48px;
  width: 129px;
  position: relative;
  border: none;
  border-radius: 28.5px;
  font-weight: 500;
  overflow: hidden;
  transition: background-color $transition-normal linear;
  color: $color-white;

  .button-progress {
    position: absolute;
    height: 100%;
    width: 0%;
    top: 0;
    left: 0;
    background: $color-primary;
    transition: width $transition-normal linear;
  }

  .button-text {
    position: relative;
  }

  &:hover {
    cursor: pointer;
  }
}

.custom-button.loading {
  background-color: var(--color-primary-200);

  &:hover {
    cursor: not-allowed;
  }
}
</style>
