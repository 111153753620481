/* eslint-disable dot-notation */
/* eslint-disable camelcase */
import amplitude from 'amplitude-js'
import { amplitudeEvents } from '@/utils/constants'
import { getDomainForCookie, getWhitelabelNameFromSubdomain, getCookie } from '@/utils/utils'

export default {
  data() {
    return {
      cookies: ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'http_referer', 'last_page_visited', 'first_page_visited', 'gclid', 'current_url']
    }
  },
  created() {
    this.setUTMCookiesFromURL()
    const { first_page_visited } = this.getUtmParameters()
    if (!first_page_visited) {
      this.setCookie('first_page_visited', window.location.href)
    }
  },
  methods: {
    getUtmParameters() {
      const params = {}

      document.cookie.split('; ').forEach(cookie => {
        const [key, value] = cookie.split('=')

        if (this.cookies?.includes(key)) {
          params[key.replace(/utm_/gi, '')] = decodeURIComponent(value)
        }
      })
      return params
    },
    getURLParams() {
      if (!this.$route?.query) return

      const urlParams = Object.entries(this.$route.query)
      const params = {}

      try {
        const incomingBase64 = urlParams[0][0].endsWith('=') ? urlParams[0][0] : urlParams[0][0] + '=='

        if (window.btoa(window.atob(urlParams[0][0])) === incomingBase64) {
          window
            .atob(urlParams[0][0])
            .split('&')
            .forEach(param => {
              const [key, value] = param.split('=')

              if (key === 'deviceId') {
                amplitude.getInstance().setDeviceId(value)
              }

              params[key] = decodeURIComponent(value)
            })

          params[urlParams[0][0]] = decodeURIComponent(urlParams[0][1])
          return params
        }
      } catch (e) {}

      urlParams.forEach(param => {
        if (this.cookies.includes(param[0])) {
          params[param[0]] = param[1]
        }
      })
      return params
    },
    filterParameters(parameters) {
      const filteredParameters = {}

      Object.keys(parameters).forEach(key => {
        if (this.cookies.includes(key) || this.cookies.includes(`utm_${key}`)) {
          filteredParameters[key] = parameters[key]
        }
      })

      return filteredParameters
    },
    setCookie(key, value, daysTillExpires = 1) {
      const domain = getDomainForCookie()
      const now = new Date()
      const expires = new Date(now.setDate(now.getDate() + 1))
      if (value.includes('/')) {
        value = encodeURIComponent(value)
      }

      document.cookie = `${key}=${value}; expires=${expires.toUTCString()}; domain=${domain}; path=/;`
    },
    setUTMCookiesFromURL() {
      const urlCookies = this.getURLParams()

      if (!urlCookies) return

      Object.keys(urlCookies).forEach(key => {
        if (this.cookies.includes(key)) {
          this.setCookie(key, urlCookies[key])
        }
      })
    },
    trackSignUpEvents(userEmail = undefined) {
      const currentUrl = window.location.href
      const utmParams = this.getUtmParameters()

      utmParams.referrer_url = utmParams.http_referer || document.referrer
      delete utmParams.http_referer

      if (this.$route?.path === '/goal/grant/sign-up') {
        this.trackLloydsGrantFinderEvent(amplitudeEvents.lloydsSMEGrants.CREATE_USER, 'Sign-up')
      }

      if (window.dataLayer) {
        window.dataLayer.push({
          url: currentUrl,
          event: 'new_account_app',
          email: userEmail || this.inputs.email
        })
      }

      this.$ma.trackEvent({
        eventType: amplitudeEvents.businessOnboarding.CREATE_USER,
        eventProperties: {
          ...utmParams,
          url: currentUrl
        }
      })
    },
    trackLloydsGrantFinderEvent(eventType, pageTitle, url = window.location.href) {
      if (getWhitelabelNameFromSubdomain() !== 'lloydsbank') return
      const params = this.getUtmParameters()

      this.$ma.trackEvent({
        eventType: eventType,
        eventProperties: {
          pageTitle: pageTitle,
          utm_source: params?.source || null,
          utm_medium: params?.medium || null,
          utm_campaign: params?.campaign || null,
          http_referer: params?.http_referer || document.referrer || 'Direct',
          first_page_visited: params?.first_page_visited || null,
          url
        }
      })
    },
    trackPageViewWithUtmParams(pageTitle, url = window.location.href) {
      const params = this.getUtmParameters()
      this.$ma.trackEvent({
        eventType: '<MP><PageView>',
        eventProperties: {
          pageTitle: pageTitle,
          region: process.env?.VUE_APP_TENANT,
          domain: url.split('.')[0],
          current_url: url,
          utm_source: params?.source || getCookie('utm_source') || null,
          utm_medium: params?.medium || getCookie('utm_medium') || null,
          utm_campaign: params?.campaign || getCookie('utm_campaign') || null,
          utm_content: params?.content || getCookie('utm_content') || null,
          utm_term: params?.term || getCookie('utm_term') || null,
          http_referer: params?.http_referer || getCookie('http_referer') || document.referrer || 'Direct'
        }
      })
    }
  }
}
